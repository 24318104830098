import "./ExistingJob.scss";
import Modal from "../Modal/Modal";
import { useEffect, useState, useCallback, useRef } from "react";
import JobCard from "./JobCard";
import JobDetails from "./JobDetails";
import TransporterDetails from "../TransporterDetails";
import JobService from "../../services/JobService";
import { JobOrder } from "../../interfaces/job.interface";
import { useJobContext } from "../../contexts/JobContext";
import JobBreakupTable from "./JobBreakupTable";
import { useLoading } from "../../contexts/LoadingContext";
import ContainerDetails from "./ContainerDetails";
import JobStatus from "./JobStatus";
import noJobInfoGraphics from "./../../assets/images/no-data-infographics.svg";
import LiveTracking from "../LiveTracking/LiveTracking";
import { useLoader } from "../../contexts/LoaderContext";

interface ExistingJobProps {
  currentSegment?: string;
}

// export default function ExistingJob() {
const ExistingJob: React.FC<ExistingJobProps> = ({ currentSegment }) => {
  const [jobs, setJobs] = useState<JobOrder[]>([]);
  const [selectedJob, setSelectedJob] = useState<JobOrder>();
  const [view, setView] = useState("jobDetails");
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { shouldFetchJobs, resetJobFetch } = useJobContext();
  const [hasMore, setHasMore] = useState(true);
  const isLoading = useRef(false);
  const page = useRef(0);
  const loader = useLoading();
  const { showLoader, hideLoader } = useLoader();

  const loadJobs = useCallback(async () => {
    isLoading.current = true;
    if (page.current === 0 && currentSegment === "Existing") showLoader();
    if (page.current > 0) loader.setLoading(true);
    try {
      const res = await JobService.getMyJobs({
        limit: 10,
        skip: page.current,
      });
      const newJobs = res.data.data.content;
      if (newJobs.length > 0) {
        setJobs((prevJobs) => [
          ...prevJobs,
          ...newJobs.filter(
            (job: JobOrder) => !prevJobs.some((j) => j.jobCode === job.jobCode)
          ),
        ]);
      }
      if (page.current === 0) {
        handleSelectJob(newJobs[0]);
      }
      console.log(hasMore);
      if (page.current < res.data.data.page.totalPages - 1) {
        console.log("setting has more");
        setHasMore(true);
        page.current += 1; // Increment the page for the next load
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error("Error loading jobs", error);
    } finally {
      isLoading.current = false;
      setTimeout(() => {
        loader.setLoading(false);
        hideLoader();
      }, 1000);
    }
  }, [hasMore, selectedJob]);

  useEffect(() => {
    loadJobs();
  }, []);

  useEffect(() => {
    if (shouldFetchJobs) {
      setJobs([]);
      page.current = 0;
      setHasMore(true);
      loadJobs(); // Initial load after reset
      resetJobFetch();
    }
  }, [shouldFetchJobs, resetJobFetch, loadJobs]);

  const handleScroll = useCallback(() => {
    const scrollContainer = document.querySelector("#jobs-list");
    if (scrollContainer) {
      const { scrollTop, scrollHeight, clientHeight } = scrollContainer;
      if (
        scrollHeight - scrollTop <= clientHeight * 1.5 &&
        hasMore &&
        !isLoading.current
      ) {
        loadJobs();
      }
    }
  }, [hasMore, loadJobs]);

  useEffect(() => {
    const scrollContainer = document.querySelector("#jobs-list");
    scrollContainer?.addEventListener("scroll", handleScroll);
    return () => {
      scrollContainer?.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  const handleSelectJob = (job: JobOrder) => {
    setSelectedJob(job);
  };

  const handleViewChange = (view: string) => {
    setView(view);
  };

  const handleUpdatedJob = (updatedJob: JobOrder) => {
    if (!updatedJob || !updatedJob.jobId) {
      console.error("Invalid updated job:", updatedJob);
      return;
    }
    setJobs((prevJobs) =>
      prevJobs.map((job) => (job.jobId === updatedJob.jobId ? updatedJob : job))
    );
    setSelectedJob(updatedJob);
  };

  return (
    <div className="flex existing-job-page">
      {!isLoading.current && jobs.length === 0 && (
        <div className="center_floater_container">
          <div className="no_data_wrapper pr-[126px]">
            <img
              className="info_graphics"
              src={noJobInfoGraphics}
              alt="No Jobs"
            />
            <div className="msg">
              There are no jobs available at the moment !
            </div>
          </div>
        </div>
      )}
      {jobs.length > 0 && (
        <>
          {/* {selectedJob && (
            <LiveTracking
              driverId={1}
              tripId={106}
              jobBreakupDetailId={112}
              trackAllTrucks={false}
              job={selectedJob}
            ></LiveTracking>
          )} */}

          <div className="w-1/2  p-[20px]" id="jobs-list">
            <JobCard
              CardData={jobs}
              selectedJobId={selectedJob?.jobCode}
              onSelect={handleSelectJob}
            />
          </div>
          <div className="w-1/2 bg-card  my-[20px] mr-[20px]  rounded-[10px] selected-job-details card-shadow">
            {!selectedJob && (
              <div className="center_floater_container">
                <div className="no_data_wrapper">
                  <img
                    className="info_graphics"
                    src={noJobInfoGraphics}
                    alt="No Jobs"
                  />
                  <div className="msg">Select a job to view the details !</div>
                </div>
              </div>
            )}
            {selectedJob && (
              <>
                <div className=" flex justify-between items-center p-[20px] pb-1">
                  <div className="text-[18px] text-gray-900">
                    #{selectedJob.jobCode}
                  </div>
                  <JobStatus jobStatus={selectedJob.jobStatus} />
                </div>
                <div className="flex px-[20px] border-b border-bottomline justify-between min-h-[47px]">
                  <div className="flex">
                    <button
                      className={`mr-2 py-2 px-4 font-w-[500] ${
                        view === "jobDetails"
                          ? "border-b border-blueText text-blueText"
                          : "text-notSelected"
                      }`}
                      onClick={() => handleViewChange("jobDetails")}
                    >
                      Job Details
                    </button>
                    <button
                      className={`py-2 px-4 font-w-[500] ${
                        view === "containerDetails"
                          ? "border-b border-blueText text-blueText"
                          : "text-notSelected"
                      }`}
                      onClick={() => handleViewChange("containerDetails")}
                    >
                      Containers
                    </button>
                    <button
                      className={`py-2 px-4 font-w-[500] ${
                        view === "transporterDetails"
                          ? "border-b border-blueText text-blueText"
                          : "text-notSelected"
                      }`}
                      onClick={() => handleViewChange("transporterDetails")}
                    >
                      Transporters
                    </button>
                  </div>
                </div>
                {view === "jobDetails" && <JobDetails job={selectedJob} />}
                {view === "containerDetails" && (
                  <ContainerDetails
                    job={selectedJob}
                    onAssignRequest={() => setIsModalOpen(true)}
                  ></ContainerDetails>
                )}
                {view === "transporterDetails" && (
                  <TransporterDetails jobOrder={selectedJob} />
                )}
              </>
            )}
          </div>
          <Modal
            className="component_modal_container"
            title="Assign Containers"
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
          >
            {selectedJob && (
              <JobBreakupTable
                job={selectedJob}
                onSave={(updatedJob) => handleUpdatedJob(updatedJob)}
                onCancel={() => setIsModalOpen(false)}
              ></JobBreakupTable>
            )}
          </Modal>
        </>
      )}
    </div>
  );
};

export default ExistingJob;
