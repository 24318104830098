import "./App.scss";
import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { Toaster } from "./components/Toaster";
import { useLoading } from "./contexts/LoadingContext";
import { useAuth } from "./contexts/AuthContext";
import { setupAxiosInterceptors } from "./AxiosInstance";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import LoadingBar from "./components/LoadingBar/LoadingBar";
import SidebarLayout from "./layouts/SidebarLayout";
import SignIn from "./pages/SignIn/SignIn";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import SignUp from "./pages/SignUp/SignUp";
import Jobs from "./pages/Jobs/Jobs";
import NotFound from "./components/NotFound/NotFound";
import ComingSoon from "./components/ComingSoon/ComingSoon";
import Profile from "./pages/Profile/Profile";
import Container from "./components/Container";
import AssignContainers from "./pages/AssignContainers/AssignContainers";
// import Profile from "./pages/Profile";
// import Settings from "./pages/Settings";
// import Reports from "./pages/Reports";
// import Transporter from "./components/Transporter";
import TripHistory from "./pages/TripHistory/TripHistory";
import TripHistoryJob from "./pages/TripHistoryJob/TripHistoryJob";


const App: React.FC = () => {
  const { isLoading } = useLoading();
  const { isAuthLoading, logout } = useAuth();

  useEffect(() => {
    if (!isAuthLoading) {
      setupAxiosInterceptors(logout);
    }
  }, [isAuthLoading, logout]); // Ensure the interceptor setup runs when the logout function is available

  return (
    <div className="App">
      <LoadingBar isLoading={isLoading} />
      <Router>
        <div className="flex">
          <div className="flex-grow p-0">
            <Routes>
              <Route path="/" element={<Navigate to="/jobs" />} />

              {/* Public Routes */}
              <Route element={<PublicRoute />}>
                <Route path="/sign-up" element={<SignUp />} />
                <Route path="/sign-in" element={<SignIn />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                {/* <Route path="/reset-password" element={<ResetPassword />} /> */}
              </Route>

              {/* Private Routes */}
              <Route element={<PrivateRoute />}>
                <Route path="/dashboard" element={<SidebarLayout />}>
                  <Route index element={<ComingSoon />} />
                </Route>
                <Route path="/transporters" element={<SidebarLayout />}>
                  <Route index element={<ComingSoon />} />
                </Route>
                <Route path="/jobs" element={<SidebarLayout />}>
                  <Route index element={<Jobs />} />
                </Route>
                <Route
                  path="/job/:jobCode/assign-containers"
                  element={<SidebarLayout />}
                >
                  <Route index element={<AssignContainers />} />
                </Route>
                <Route path="/trip-history" element={<SidebarLayout />}>
                  <Route index element={<TripHistory />} />
                </Route>
                <Route path="/trip-history/job/:jobId" element={<SidebarLayout />}>
                  <Route index element={<TripHistoryJob/>} />
                </Route>
                <Route path="/profile" element={<SidebarLayout />}>
                  <Route index element={<Profile />} />
                </Route>
                <Route path="/settings" element={<SidebarLayout />}>
                  <Route index element={<ComingSoon />} />
                </Route>
              </Route>

              {/* Catch-all route for 404 */}
              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
        </div>
      </Router>
      <Toaster></Toaster>
    </div>
  );
};

export default App;
