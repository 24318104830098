import "./TripHistoryComponent.scss";
import React, { useRef, useState } from "react";
import IndividualJob from "./IndividualJob";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import CustomDateRange from "../CoreComponents/CustomDateRange";
import SearchBar from "../SearchBar/SearchBar";

import { Formik, Form, Field, ErrorMessage, useFormikContext } from "formik";

import { ReactComponent as Filtericon } from "../../assets/icons/filter-icon.svg";
import { ReactComponent as Searchicon } from "../../assets/icons/search-icon.svg";
import { ReactComponent as Downloadicon } from "../../assets/icons/download-icon.svg";
// import search from "../../assets/search.png";
// import filtericon from "../../assets/filter.png";
// import downloadicon from "../../assets/download.png";
import DateTimePickerComponent from "../DateTimePicker/DateTimePicker";
import * as Yup from "yup"; // Optional for validation

const validationSchema = Yup.object({
  startDate: Yup.number().required("Start Date is required"),
  endDate: Yup.number().required("End Date is required"),
});

const TripHistoryComponent = () => {
  const [jobs] = useState([
    ...Array(10).fill({
      id: 1,
      jobId: "17E888",
      containerNo: "ABC1234567",
      sizeType: "40OT",
      transporter: "Transporter A",
      truckNo: "MH12AB1234",
      source: "Mumbai, Maharashtra, India",
      destination: "Goa, India",
      dateTime: 1721896200,
    }),
    {
      id: 2,
      jobId: "17E888",
      containerNo: "DEF7654321",
      sizeType: "20HQ",
      transporter: "Transporter B",
      truckNo: "MH12XY7890",
      source: "Mumbai, Maharashtra, India",
      destination: "Goa, India",
      dateTime: 1721904300,
    },
    {
      id: 3,
      jobId: "18F999",
      containerNo: "XYZ7890123",
      sizeType: "40HQ",
      transporter: "Transporter C",
      truckNo: "TN12AB1234",
      source: "Chennai, Tamil Nadu, India",
      destination: "Kochi, Kerala, India",
      dateTime: 1724562000,
    },
    {
      id: 4,
      jobId: "19G000",
      containerNo: "KLM1234567",
      sizeType: "20HQ",
      transporter: "Transporter D",
      truckNo: "WB12XY7890",
      source: "Kolkata, West Bengal, India",
      destination: "Visakhapatnam, Andhra Pradesh, India",
      dateTime: 1727156100,
    },
    {
      id: 5,
      jobId: "20H111",
      containerNo: "NOP1234567",
      sizeType: "60HQ",
      transporter: "Transporter E",
      truckNo: "DL12XY7890",
      source: "Delhi, India",
      destination: "Jaipur, Rajasthan, India",
      dateTime: 1727923200,
    },
    {
      id: 6,
      jobId: "20H112",
      containerNo: "QRS9876543",
      sizeType: "40FL",
      transporter: "Transporter F",
      truckNo: "DL12AB4321",
      source: "Delhi, India",
      destination: "Jaipur, Rajasthan, India",
      dateTime: 1727990700,
    },
  ]);
  const [selectedJobId, setSelectedJobId] = useState(null);

  const dateTimePickerStartRef = useRef<{ reset: () => void }>(null);
  const dateTimePickerEndRef = useRef<{ reset: () => void }>(null);

  // const [startDate, setStartDate] = useState<number | null>(null);
  // const [endDate, setEndDate] = useState<number | null>(null);
  const [searchInput, setSearchInput] = useState(" ");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10); // Default items per page
  const totalPages = Math.ceil(jobs.length / itemsPerPage);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleItemsPerPageChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1); // Reset to first page on items per page change
  };

  const paginate = (jobs: any[], pageNumber: number, itemsPerPage: number) => {
    const startIndex = (pageNumber - 1) * itemsPerPage;
    return jobs.slice(startIndex, startIndex + itemsPerPage);
  };

  const paginatedJobs = paginate(jobs, currentPage, itemsPerPage);

  const navigate = useNavigate();

  const handleMoreClick = (job: any) => {
    setSelectedJobId(job.jobId);
    navigate(`/trip-history/job/${job.jobId}`);
  };

  // const handleDateRangeChange = (
  //   startTimestamp: number | null,
  //   endTimestamp: number | null
  // ) => {
  //   setStartDate(startTimestamp);
  //   setEndDate(endTimestamp);
  // };

  const handleFilterClick = (values: any) => {
    console.log(
      "Start Date:",
      values.startDate
        ? moment.unix(values.startDate).format("DD-MM-YYYY hh:mm A")
        : "Not selected"
    );
    console.log(
      "End Date:",
      values.endDate
        ? moment.unix(values.endDate).format("DD-MM-YYYY hh:mm A")
        : "Not selected"
    );
  };

  // const handleSearchClick = () => {
  //   console.log("Search Input Value:", searchInput);
  // };

  const handleSearch = (query: string) => {
    console.log("Search query:", query);
  };

  return (
    <div className="p-4 h-fill">
      <div className="flex justify-between mb-5 bg-card rounded-lg border border-gray-200">
        <div className="text-left flex-1 p-6 border border-gray-200">
          <span className="block text-sm font-bold text-grayText mb-2">
            Total Trips
          </span>
          <span
            className="block text-4xl font-bold"
            style={{ color: "#59585c" }}
          >
            23,573
          </span>{" "}
          {/* Total Trips */}
        </div>
        <div className="text-left flex-1 p-5 border border-gray-200">
          <span className="block text-sm font-bold text-grayText mb-2">
            Total Container
          </span>
          <span
            className="block text-4xl font-bold"
            style={{ color: "#59585c" }}
          >
            1,25,762
          </span>{" "}
          {/* Total Container */}
        </div>
        <div className="text-left flex-1 p-5 border border-gray-200">
          <span className="block text-sm font-bold text-grayText mb-2">
            20ft
          </span>
          <span
            className="block text-4xl font-bold"
            style={{ color: "#1d3557" }}
          >
            86,773
          </span>{" "}
          {/* 20ft */}
        </div>
        <div className="text-left flex-1 p-5 border border-gray-200">
          <span className="block text-sm font-bold text-grayText mb-2">
            40ft
          </span>
          <span
            className="block text-4xl font-bold"
            style={{ color: "#457b9d" }}
          >
            36,461
          </span>{" "}
          {/* 40ft */}
        </div>
        <div className="text-left flex-1 p-5 border border-gray-200">
          <span className="block text-sm font-bold text-grayText mb-2">
            45ft
          </span>
          <span
            className="block text-4xl font-bold"
            style={{ color: "#69b9bc" }}
          >
            2,528
          </span>{" "}
          {/* 45ft */}
        </div>
      </div>
      <div className="  flex justify-between items-center min-h-[120px]">
        <div className="p-2 mt-2">
          <SearchBar onSearch={handleSearch} />
        </div>

        <div className="flex items-center space-x-2">
          {/* <CustomDateRange
            showLabels={false}
            onDateRangeChange={handleDateRangeChange}
          /> */}
          <Formik
            initialValues={{ startDate: null, endDate: null }}
            validationSchema={validationSchema}
            onSubmit={(values) => handleFilterClick(values)}
          >
            {({ setFieldValue, setFieldTouched, values }) => (
              <Form>
                <div className="flex items-center space-x-2 ">
                  <div className="form-group ">
                    <label htmlFor="startDate" className="required_field_label">
                      Start Date
                    </label>
                    <DateTimePickerComponent
                      ref={dateTimePickerStartRef}
                      onBlurred={() => setFieldTouched("startDate", true)}
                      className={values.startDate ? "invalid_field" : " "}
                      onChange={(date: Date | null) => {
                        if (date) {
                          const unixTimestamp = Math.floor(
                            new Date(date).getTime() / 1000
                          );
                          setFieldValue("startDate", unixTimestamp);
                        } else {
                          setFieldValue("startDate", null);
                        }
                      }}
                    ></DateTimePickerComponent>
                    <ErrorMessage
                      name="startDate"
                      component="div"
                      className="validation_error"
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="endDate" className="required_field_label">
                      End Date
                    </label>
                    <DateTimePickerComponent
                      ref={dateTimePickerEndRef}
                      onBlurred={() => setFieldTouched("endDate", true)}
                      className={values.endDate ? "invalid_field" : " "}
                      onChange={(date: Date | null) => {
                        if (date) {
                          const unixTimestamp = Math.floor(
                            new Date(date).getTime() / 1000
                          );
                          setFieldValue("endDate", unixTimestamp);
                        } else {
                          setFieldValue("endDate", null);
                        }
                      }}
                    />
                    <ErrorMessage
                      name="endDate"
                      component="div"
                      className="validation_error"
                    />
                  </div>

                  <button
                    type="submit"
                    className="flex items-center px-4 py-3.5 bg-white border border-grayText rounded hover:bg-grayText transition"
                  >
                    <Filtericon className="text-blueText mr-2" />
                    Filter
                  </button>
                </div>
              </Form>
            )}
          </Formik>

          <button className="flex items-center bg-white border border-grayText px-4 py-3.5  rounded hover:bg-grayText transition">
            <Downloadicon className="text-blueText" />
            <span className="whitespace-nowrap ml-2 ">Download Report</span>
          </button>
        </div>
      </div>
      {/* Job Orders Table */}
      <div className="max-h-[540px] overflow-y-auto">
      <table className="min-w-full font-semibold bg-white rounded-md">
  <thead>
    <tr className="bg-background text-grayText">
      <th className="px-4 text-center">
        <input type="checkbox" />
      </th>
      <th className="px-4 text-center">SI No.</th>
      <th className="px-4 text-center">Job No.</th>
      <th className="px-4 text-center">Container No.</th>
      <th className="px-4 text-center">Size & Type</th>
      <th className="px-4 text-center">Transporter</th>
      <th className="px-4 text-center">Truck No.</th>
      <th className="px-4 text-center">Source</th>
      <th className="px-4 text-center">Destination</th>
      <th className="px-4 text-center">Created Date & Time</th>
      <th className="px-4 text-center">More</th>
    </tr>
  </thead>
  <tbody>
    {paginatedJobs.map((job, index) => (
      <tr
        key={job.id}
        className="border-t hover:bg-gray-50 text-transporterText"
      >
        <td className="px-4 text-center">
          <input type="checkbox" />
        </td>
        <td className="px-4 text-center">{index + 1}</td>
        <td
          className="px-4 py-2 text-center underline hover:text-blueText cursor-pointer"
          onClick={() => handleMoreClick(job)}
        >
          {job.jobId}
        </td>
        <td className="px-4 text-center">{job.containerNo}</td>
        <td className="px-4 text-center">{job.sizeType}</td>
        <td className="px-4 text-center">{job.transporter}</td>
        <td className="px-4 text-center">{job.truckNo}</td>
        <td className="px-4 text-center">{job.source}</td>
        <td className="px-4 text-center">{job.destination}</td>
        <td className="px-4 text-center">
          {moment.unix(job.dateTime).format("DD-MM-YYYY hh:mm A")}
        </td>
        <td className="px-4 text-center">
          <button
            className="bg-gray-400 text-white px-2 rounded"
            onClick={() => handleMoreClick(job)}
          >
            ...
          </button>
        </td>
      </tr>
    ))}
  </tbody>
</table>

      </div>
      <div className="flex flex-col items-center mt-4 space-y-4">
        {/* Pagination Controls */}
        <div className="flex justify-end items-center space-x-2 w-full">
          {/* Items Per Page Selector */}

          {/* Previous Button */}
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className={`px-4 py-2 rounded ${
              currentPage === 1
                ? "bg-white text-gray-500 cursor-not-allowed"
                : "bg-white hover:bg-gray-300 text-gray-700"
            }`}
          >
            Previous
          </button>

          {/* Display pagination numbers */}
          {Array.from({ length: totalPages }, (_, i) => i + 1)
            .slice(
              Math.max(0, currentPage - 3),
              Math.min(totalPages, currentPage + 2)
            )
            .map((page) => (
              <button
                key={page}
                onClick={() => handlePageChange(page)}
                className={`px-4 py-2 rounded ${
                  currentPage === page
                    ? "bg-blueText text-white"
                    : "bg-white hover:bg-gray-300 text-gray-700"
                }`}
              >
                {page}
              </button>
            ))}

          {/* Ellipsis and Last Page */}
          {currentPage < totalPages - 2 && (
            <>
              <span className="px-4 py-2 text-gray-500">...</span>
              <button
                onClick={() => handlePageChange(totalPages)}
                className="px-4 py-2 rounded bg-white hover:bg-gray-300 text-gray-700"
              >
                {totalPages}
              </button>
            </>
          )}

          {/* Next Button */}
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className={`px-4 py-2 rounded ${
              currentPage === totalPages
                ? "bg-white text-gray-500 cursor-not-allowed"
                : "bg-white hover:bg-gray-300 text-gray-700"
            }`}
          >
            Next
          </button>
          <select
            value={itemsPerPage}
            onChange={handleItemsPerPageChange}
            className="border border-gray-300 rounded px-4 py-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value={2}>2</option>
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
          </select>
        </div>
      </div>
      {selectedJobId && <IndividualJob />} {/* To call component here itself */}
    </div>
  );
};

export default TripHistoryComponent;
