import { api } from "../AxiosInstance";

// Define service functions for general data related API calls
const TransporterService = {
  getMyTransporters: async () => {
    // const response = await api.get("/fed/transporters?fedId=1");
    const response = await api.get("/fed/transporters");
    return response;
  },
};

export default TransporterService;
