import React, { forwardRef, useImperativeHandle, useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import calendarIconSVG from "../../assets/icons/calendar-icon.svg";

import { ReactComponent as CalendarIcon2 } from "../../assets/icons/calendar-icon.svg";

interface DateTimePickerComponentProps {
  label?: string;
  value?: Date | null;
  onChange?: (newValue: Date | null) => void;
  className?: string;
  onInputClicked?: () => void;
  onBlurred?: () => void;
}

const CalendarIcon = () => (
  <img src={calendarIconSVG} alt="Select Date and Time" />
);

// const DateTimePickerComponent: React.FC<DateTimePickerComponentProps> = ({
//   label = "Select Date and Time",
//   value,
//   onChange,
//   className,
//   onInputClicked,
//   onBlurred,
// }) => {

const DateTimePickerComponent = forwardRef<
  { reset: () => void },
  DateTimePickerComponentProps
>(
  (
    {
      label = "Select Date and Time",
      value,
      onChange,
      className,
      onInputClicked,
      onBlurred,
    },
    ref
  ) => {
    const [selectedDate, setSelectedDate] = useState<Date | null>(
      value || null
    );
    const currentDate = new Date();

    // Expose reset function to parent
    useImperativeHandle(ref, () => ({
      reset() {
        setSelectedDate(value || null); // Reset to initial value
      },
    }));

    const handleDateChange = (newValue: Date | null) => {
      setTimeout(() => {
        setSelectedDate(newValue);
        if (onChange) onChange(newValue);
      }, 150);
    };

    const handleInputBlur = () => {
      setTimeout(() => {
        if (onBlurred) onBlurred();
      }, 150);
    };

    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateTimePicker
          onClose={handleInputBlur}
          minDate={currentDate}
          // minTime={currentDate}
          format="dd/MMM/yyyy hh:mm aa"
          className={className}
          label={label}
          value={selectedDate}
          onChange={handleDateChange}
          // slots={{ inputAdornment: CalendarIcon }}
          slotProps={{
            // inputAdornment: {
            // position: "start",
            // },
            // openPickerIcon: CalendarIcon,
            toolbar: {
              hidden: true,
            },
            textField: {
              InputProps: {
                placeholder: `${label}`,
              },
              role: "button",
              onClick: onInputClicked,
              onBlur: handleInputBlur,
            },
          }}
        />
      </LocalizationProvider>
    );
  }
);

export default DateTimePickerComponent;
