import { api } from "../AxiosInstance";

// Define service functions for general data related API calls
const MetaDataService = {
  getContainerSizes: async () => {
    const response = await api.get("/master/container-sizes");
    return response;
  },

  getContainerTypes: async () => {
    const response = await api.get("/master/container-types");
    return response;
  },
};

export default MetaDataService;
