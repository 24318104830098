import "./SideBar.scss";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Logo from "../../assets/st-app-icon.svg";

import { ReactComponent as TruckIcon } from "../../assets/icons/truck.svg";
import { ReactComponent as JobsIcon } from "../../assets/icons/jobs-icon.svg";
import { ReactComponent as TripHistoryIcon } from "../../assets/icons/trip-history-icon.svg";
import { ReactComponent as ProfileIcon } from "../../assets/icons/profile-icon.svg";
import { ReactComponent as SettingsIcon } from "../../assets/icons/settings-icon.svg";
import { ReactComponent as DashboardIcon } from "../../assets/icons/dashboard-icon.svg";
import { ReactComponent as LogoutIcon } from "../../assets/icons/logout-icon.svg";
import { useAuth } from "../../contexts/AuthContext";
import ConfirmDialog from "../ConfirmDialog/ConfirmDialog";

interface SidebarItem {
  name: string;
  path: string;
  icon: JSX.Element;
}

const SideBar: React.FC = () => {
  const { logout } = useAuth();
  const mainSidebarItems: SidebarItem[] = [
    // { name: "Dashboard", path: "/dashboard", icon: <DashboardIcon /> },
    { name: "Jobs", path: "/jobs", icon: <JobsIcon /> },
    { name: "Trip History", path: "/trip-history", icon: <TripHistoryIcon /> },
    // { name: "Transporters", path: "/transporters", icon: <TruckIcon /> },
  ];

  const subSidebarItems: SidebarItem[] = [
    { name: "Profile", path: "/profile", icon: <ProfileIcon /> },
    // { name: "Settings", path: "/settings", icon: <SettingsIcon /> },
  ];

  const [openConfirm, setOpenConfirm] = useState(false);

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleConfirmAction = () => {
    logout();
    setOpenConfirm(false);
  };

  return (
    <>
      <div className="sidebar_style">
        <img
          className="logo px-[16px] pt-[16px] pb-[35px] max-w-[240.5px]"
          src={Logo}
          alt="Smart Transport Logo"
        />
        <div className="sidebar_items">
          <nav>
            <ul>
              {mainSidebarItems.map((item) => (
                <li key={item.path}>
                  <NavLink
                    to={item.path}
                    className={({ isActive }) => (isActive ? "active" : "")}
                  >
                    <span className="icon">{item.icon}</span>
                    <span className="text">{item.name}</span>
                  </NavLink>
                </li>
              ))}
            </ul>
            <div className="divider"></div>
            <ul>
              {subSidebarItems.map((item) => (
                <li key={item.path}>
                  <NavLink
                    to={item.path}
                    className={({ isActive }) => (isActive ? "active" : "")}
                  >
                    <span className="icon">{item.icon}</span>
                    <span className="text">{item.name}</span>
                  </NavLink>
                </li>
              ))}
            </ul>
          </nav>
          <button className="logout_btn" onClick={handleOpenConfirm}>
            <span className="icon">
              <LogoutIcon />
            </span>
            <span>Logout</span>
          </button>
        </div>
      </div>

      <ConfirmDialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        onConfirm={handleConfirmAction}
        title="Logout Confirmation"
        message="Are you sure you want to logout?"
        confirmText="Yes"
      />
    </>
  );
};

export default SideBar;
