import "./ProfileButton.scss";
import React from "react";
import { ReactComponent as Avatar } from "../../assets/images/avatar.svg";
import { useNavigate } from "react-router-dom";

const ProfileButton: React.FC<any> = () => {
  const navigate = useNavigate();
  return (
    <div
      className="profile-button relative inline-block"
      title="Click to view profile"
    >
      <button
        onClick={() => navigate("/profile")}
        className="flex items-center space-x-3 focus:outline-none"
      >
        <Avatar className="h-[45px] w-[45px] rounded-full"></Avatar>
        <div className="text-left">
          <div className="font-[500] text-[16px] text-mainText">
            Meraki Express
          </div>
          <div className="text-[14px] text-subText leading-[16px]">
            Super Admin
          </div>
        </div>
      </button>
    </div>
  );
};

export default ProfileButton;
