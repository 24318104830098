import "./LiveTracking.scss";
import React, { useEffect, useState } from "react";
import {
  ContainerStats,
  JobBreakUpDetail,
  JobOrder,
} from "../../interfaces/job.interface";
import {
  containerSizeStringToNumber,
  formatIsoDate,
  getContainerStatsFromJobOrder,
  getContainerStatus,
  transformTruckNumber,
} from "../../utils/general";
import Modal from "../Modal/Modal";
import TrackingComponent from "../Tracking/TrackingComponent";
import MapComponent from "../Tracking/MapComponent";
import MapWithPath from "../MapWithPath/MapWithPath";
import { ReactComponent as LocationIcon } from "../../assets/icons/location-pin-icon.svg";
import { ReactComponent as TruckIcon } from "../../assets/icons/truck.svg";
import { ReactComponent as RightIcon } from "../../assets/icons/right-arrow.svg";
import { ReactComponent as StatusIcon } from "../../assets/icons/job-status-icon.svg";
import { ReactComponent as TickIcon } from "../../assets/icons/tick.svg";
import { ReactComponent as LocationTickIcon } from "../../assets/icons/location-pin-with-tick.svg";
import noJobInfoGraphics from "./../../assets/images/no-data-infographics.svg";
import JobService from "../../services/JobService";
import JobStatus from "../Jobs/JobStatus";
import { ReactComponent as CicleIcon } from "../../assets/icons/circle.svg";

interface LiveTrackingProps {
  job: JobOrder;
  onAssignRequest?: () => void;
  driverId?: number;
  tripId?: number;
  trackAllTrucks?: false;
  jobBreakupDetailId?: number;
}

const LiveTracking: React.FC<LiveTrackingProps> = ({
  job,
  driverId,
  onAssignRequest,
  tripId,
  trackAllTrucks,
  jobBreakupDetailId,
}) => {
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [distance, setDistance] = useState<string>("");
  const [estimatedTime, setEstimatedTime] = useState<string>("");
  const [containerStats, setContainerStats] = useState<ContainerStats>();
  const [selectedJobBreakUpDetailsId, setSelectedJobBreakUpDetailsId] =
    useState<number>();

  const [stats, setStats] = useState({
    totalContainers: 0,
    totalAssigned: 0,
    pendingToAssign: 0,
    assignmentByTransporters: new Map<string, number>(), // Map to store transporter name and assigned count
  });

  const [isTrackingModalOpen, setIsTrackingModalOpen] =
    useState<boolean>(false);

  const showTracking = () => {
    setIsTrackingModalOpen(true);
  };

  const hideTracking = () => {
    setIsTrackingModalOpen(false);
  };

  const loadTripDetails = async () => {
    if (driverId && tripId) {
      try {
        const res = await JobService.getJobDetails(1);
        console.log("job details");
        console.log(res);
      } catch (error) {
        console.error("Error in loading trip details", error);
      } finally {
        console.log("object");
      }

      try {
        const res = await JobService.trackDriver(driverId);
        console.log("driver details");
        console.log(res);
      } catch (error) {
        console.error("Error in loading trip details", error);
      } finally {
        console.log("object");
      }
    }
  };

  useEffect(() => {
    console.log("object");
    console.log("tripId : " + tripId);
    console.log("trackAllTrucks " + trackAllTrucks);
    // loadTripDetails();
    // setContainerStats(getContainerStatsFromJobOrder(job));
    // console.log(containerStats);
  }, []);

  useEffect(() => {
    if (job) {
      if (jobBreakupDetailId)
        setSelectedJobBreakUpDetailsId(jobBreakupDetailId);
      setContainerStats(getContainerStatsFromJobOrder(job));
      loadMap();
    }
  }, [job]);

  const loadMap = () => {
    if (job) {
      const mapOptions: google.maps.MapOptions = {
        // mapId: "TERRAIN",
        streetViewControl: false,
        fullscreenControl: false,
        zoom: 8,
        center: {
          lat: parseFloat(job.sourceLatitude),
          lng: parseFloat(job.sourceLongitude),
        },
        styles: [
          {
            elementType: "labels",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "administrative",
            elementType: "geometry",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "administrative.land_parcel",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "administrative.neighborhood",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "poi",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "road",
            elementType: "labels.icon",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "transit",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
        ],
      };

      if (window.google) {
        const mapInstance = new window.google.maps.Map(
          document.getElementById("map") as HTMLElement,
          mapOptions
        );

        setMap(mapInstance);

        // google.maps.event.addListenerOnce(mapInstance, "idle", () => {
        //   console.log("idle");
        //   mapInstance.panBy(0, 0); // Adjust '150' based on panel width
        // });

        const sourcePosition = {
          lat: parseFloat(job.sourceLatitude),
          lng: parseFloat(job.sourceLongitude),
        };
        const destinationPosition = {
          lat: parseFloat(job.destLatitude),
          lng: parseFloat(job.destLongitude),
        };

        const createMarkerWithLabel = (
          position: google.maps.LatLngLiteral,
          title: string,
          label: string,
          imageUrl?: string,
          iconColor?: string
        ) => {
          const markerOptions: google.maps.MarkerOptions = {
            position,
            map: mapInstance,
            title,
            label,
            animation: google.maps.Animation.DROP,
          };

          markerOptions.label = {
            text: label,
            // color: "#59585b",
            color: "#000",
            fontWeight: "400",
          };

          if (iconColor) {
            markerOptions.icon = {
              path: "M10.5 0.368164C4.97662 0.368164 0.5 4.84586 0.5 10.3682C0.5 13.4471 1.89154 16.1999 4.07974 18.0348L10.5 24.4562L16.9203 18.0359C19.1085 16.2011 20.5 13.4482 20.5 10.3692C20.5 4.84586 16.0231 0.368164 10.5 0.368164ZM10.5 17.0312C6.99332 17.0312 4.15067 14.1885 4.15067 10.6818C4.15067 7.17624 6.99332 4.33359 10.5 4.33359C14.0067 4.33359 16.8493 7.17624 16.8493 10.6829C16.8493 14.1885 14.0067 17.0312 10.5 17.0312Z",
              fillColor: iconColor,
              fillOpacity: 1.0,
              strokeColor: "#000000",
              strokeWeight: 1,
              scale: 1,
              anchor: new google.maps.Point(12, 24),
            };
          }

          if (imageUrl) {
            markerOptions.icon = {
              url: imageUrl,
              scaledSize: new google.maps.Size(30, 30), // Adjust size as needed
            };
          }
          new window.google.maps.Marker(markerOptions);
        };

        createMarkerWithLabel(
          sourcePosition,
          job.source,
          job.source,
          "",
          "#cbd2d9"
        );

        createMarkerWithLabel(
          destinationPosition,
          job.destination,
          job.destination,
          "",
          "#19b5fe"
        );

        const directionsService = new google.maps.DirectionsService();
        const directionsRenderer = new google.maps.DirectionsRenderer({
          suppressMarkers: true,
          polylineOptions: {
            strokeColor: "#027cb5",
            strokeWeight: 3,
          },
        });
        directionsRenderer.setMap(mapInstance);

        directionsService.route(
          {
            origin: sourcePosition,
            destination: destinationPosition,
            travelMode: google.maps.TravelMode.DRIVING,
            provideRouteAlternatives: false,
            avoidHighways: false,
            avoidTolls: false,
          },
          (result, status) => {
            if (status === google.maps.DirectionsStatus.OK && result) {
              directionsRenderer.setDirections(result);

              // distance and estimated time from google api
              const leg = result.routes[0].legs[0];
              if (leg) {
                setDistance(leg.distance?.text || "N/A");
                setEstimatedTime(leg.duration?.text || "N/A");
              }
            } else {
              console.error(`Error fetching directions: ${status}`, result);
            }
          }
        );

        const trafficLayer = new google.maps.TrafficLayer();
        trafficLayer.setMap(mapInstance);
      }
    }
  };

  const handleTrackSelection = (containerDetails: JobBreakUpDetail) => {
    console.log(containerDetails);
    setSelectedJobBreakUpDetailsId(containerDetails.jobBreakupDetailId);
  };

  return (
    <div className="live-tracking-component">
      <div className="live-stats absolute  w-1/4  z-20 bg-card">
        {/* Job Header and Job Details */}
        <div className="flex justify-between items-center title">
          <div className="left job_id">#{job.jobCode}</div>
          <div className={`job_status ${job.jobStatus}`}>
            {/* <JobStatus jobStatus={job.jobStatus} /> */}
            <JobStatus jobStatus={"IN_PROGRESS"} />
          </div>
        </div>

        <div className="src_dest_cover">
          <div className="src-dest">
            <div className="src">
              <CicleIcon className="source_icon" />
              <div
                className="source_name truncate-two-lines"
                title={job.source}
              >
                {job.source}
              </div>
            </div>
            <div className="route"></div>
            <div className="dest">
              <CicleIcon className="destination_icon" />
              <div
                className="destination_name truncate-two-lines"
                title={job.destination}
              >
                {job.destination}
              </div>
            </div>
          </div>
        </div>

        {/* Job Status Component */}
        <div className="job_details">
          <div className="text-left text-grayText status_title">Job Status</div>
          <div className="flex justify-between items-center">
            <span className="total_dropped_containers">
              {containerStats &&
                Math.round(containerStats.totalJobStatusPercentage)}
              %
            </span>
            <span className="percentage">
              <span className="total_dropped_containers">
                {containerStats?.totalDroppedContainers}
              </span>
              /
              <span className="total_containers">
                {containerStats?.totalContainer} Dropped
              </span>
            </span>
          </div>
          {/* Progress Bar */}
          <div className="w-full h-3 job_progress_cover">
            <div
              className="h-3 job_progress"
              style={{ width: `${containerStats?.totalJobStatusPercentage}%` }}
            ></div>
          </div>
        </div>

        {/* Container List */}
        <div className="truck_list">
          {job.jobBreakups.flatMap((breakup, index) => {
            const previousCount = job.jobBreakups
              .slice(0, index) // Only consider breakups before the current one
              .reduce(
                (acc, prevBreakup) =>
                  acc +
                  prevBreakup.jobBreakUpDetails.filter(
                    (detail) => detail.containerNumber
                  ).length,
                0
              );

            return breakup.jobBreakUpDetails
              .filter((containerDetail) => containerDetail.containerNumber)
              .map((containerDetail, detailIndex) => (
                <div
                  key={previousCount + detailIndex + 1}
                  className={`truck_card mb-4 ${
                    containerDetail.jobBreakupDetailId ===
                    selectedJobBreakUpDetailsId
                      ? "selected_truck"
                      : ""
                  }`}
                >
                  <div className="flex justify-between items-center header">
                    <div className="flex items-center">
                      <TruckIcon className="text-[#a3a8b4]" />
                      <span className="ml-[6px] truck_no">
                        {containerDetail.truckIdName
                          ? transformTruckNumber(
                              containerDetail.truckIdName.name
                            )
                          : "N/A"}
                      </span>
                    </div>

                    {containerDetail.jobBreakupDetailId ===
                    selectedJobBreakUpDetailsId ? (
                      <div className="tracking-style">
                        <StatusIcon></StatusIcon>
                        <span>Tracking</span>
                      </div>
                    ) : (
                      <div
                        className="link-style"
                        onClick={() => handleTrackSelection(containerDetail)}
                      >
                        Track
                      </div>
                    )}
                  </div>

                  {/* Grid Layout for Container Details */}
                  <div className="container_details grid grid-cols-2 gap-2  text-transporterText">
                    <div className="label">
                      <span>Container No</span>
                    </div>
                    <div>
                      <span className="value">
                        {containerDetail.containerNumber}
                      </span>
                    </div>

                    <div className="label">
                      <span>Size & Type</span>
                    </div>
                    <div>
                      <span className="value">
                        {containerSizeStringToNumber(breakup.containerSize)}{" "}
                        {breakup.containerType}
                      </span>
                    </div>

                    <div className="label">
                      <span>ETA</span>
                    </div>
                    <div className="value">
                      <span>ETA</span>
                    </div>
                  </div>
                </div>
              ));
          })}
        </div>
      </div>

      <div id="map" className="map_style" style={containerStyle}></div>
    </div>
  );
};

export default LiveTracking;

const containerStyle = {
  width: "100%",
  height: "100%",
};
