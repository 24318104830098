import React, {
  createContext,
  useState,
  useContext,
  useCallback,
  ReactNode,
} from "react";

interface JobContextType {
  shouldFetchJobs: boolean;
  triggerJobFetch: () => void;
  resetJobFetch: () => void;
}

const defaultJobContext: JobContextType = {
  shouldFetchJobs: false,
  triggerJobFetch: () => {},
  resetJobFetch: () => {},
};

export const JobContext = createContext<JobContextType>(defaultJobContext);

export const JobProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  // console.log("job context");
  const [shouldFetchJobs, setShouldFetchJobs] = useState(false);

  const triggerJobFetch = useCallback(() => {
    console.log("triggerJobFetch");
    setShouldFetchJobs(true);
  }, []);

  const resetJobFetch = useCallback(() => {
    console.log("resetJobFetch");
    setShouldFetchJobs(false);
  }, []);

  return (
    <JobContext.Provider
      value={{ shouldFetchJobs, triggerJobFetch, resetJobFetch }}
    >
      {children}
    </JobContext.Provider>
  );
};

export const useJobContext = () => useContext(JobContext);
