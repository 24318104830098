import "./JobStatus.scss";
import React, { useMemo } from "react";
import { ReactComponent as CompletedIcon } from "../../assets/icons/tick.svg";
import { ReactComponent as InProgressIcon } from "../../assets/icons/job-status-icon.svg";
import { ReactComponent as CancelledIcon } from "../../assets/icons/cancel.svg";

interface JobStatusProps {
  jobStatus: string;
}

const JobStatus: React.FC<JobStatusProps> = React.memo(({ jobStatus }) => {
  const { label, backgroundColor, textColor, icon } = useMemo(() => {
    const statusStyles: Record<
      string,
      {
        label: string;
        backgroundColor: string;
        textColor: string;
        icon?: JSX.Element;
      }
    > = {
      INITIATED: {
        label: "Initiated",
        backgroundColor: "#f3f4f6",
        textColor: "#59585b",
        // label: "Pending",
        // backgroundColor: "#e3003417",
        // textColor: "#E30034",
      },
      ASSIGNED: {
        label: "Assigned",
        backgroundColor: "#047cb517",
        textColor: "#047cb5",
      },
      IN_PROGRESS: {
        label: "In Progress",
        backgroundColor: "transparent",
        textColor: "#D6A501",
        icon: <InProgressIcon />,
      },
      COMPLETED: {
        label: "Completed",
        backgroundColor: "transparent",
        textColor: "#01BA65",
        icon: <CompletedIcon />,
      },
      CANCELLED: {
        label: "Cancelled",
        backgroundColor: "transparent",
        textColor: "#E30034",
        icon: <CancelledIcon />,
      },
      PENDING: {
        label: "Pending",
        backgroundColor: "#D6A501",
        textColor: "#fff",
      },
    };

    return (
      statusStyles[jobStatus] || {
        label: "Unknown",
        backgroundColor: "#808080", // Gray for unknown status
        textColor: "#FFFFFF",
      }
    );
  }, [jobStatus]);

  return (
    <span
      className="job-status"
      style={{
        backgroundColor,
        color: textColor,
        padding: "4px 12px",
        borderRadius: "50px",
        fontWeight: "500",
        fontSize: "14px",
        display: "inline-flex",
        alignItems: "center",
        gap: "6px",
      }}
    >
      {icon && <span className="status-icon">{icon}</span>}
      {label}
    </span>
  );
});

export default JobStatus;
