import React, {
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
  forwardRef,
} from "react";

interface GoogleAutocompleteProps {
  className?: string;
  placeholder?: string;
  onPlaceSelected: (place: google.maps.places.PlaceResult) => void;
  onCleared: () => void;
  onBlurred?: () => void;
}

export interface GoogleAutoCompleteRef {
  reset: () => void;
}

const GoogleAutoComplete = forwardRef<
  GoogleAutoCompleteRef,
  GoogleAutocompleteProps
>(({ className, placeholder, onPlaceSelected, onCleared, onBlurred }, ref) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [autocomplete, setAutocomplete] =
    useState<google.maps.places.Autocomplete | null>(null);
  const [inputValue, setInputValue] = useState<string>("");
  const [placeSelected, setPlaceSelected] = useState(false);

  useEffect(() => {
    let autocompleteInstance: google.maps.places.Autocomplete | null = null;

    if (inputRef.current && window.google) {
      if (!autocomplete) {
        autocompleteInstance = new window.google.maps.places.Autocomplete(
          inputRef.current,
          {
            componentRestrictions: { country: "in" },
          }
        );

        autocompleteInstance.addListener("place_changed", () => {
          const place = autocompleteInstance!.getPlace();
          if (place && place.geometry && place.name) {
            onPlaceSelected(place);
            setInputValue(place.name);
            setPlaceSelected(true);
          }
        });

        setAutocomplete(autocompleteInstance);
      }
    }
  }, [autocomplete, onPlaceSelected]);

  // Handle input change to detect if input is cleared
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInputValue(value);
    if (!value) {
      onCleared();
    }
  };

  // Handle input focus to trigger dropdown
  const handleInputFocus = () => {
    if (inputRef.current) {
      inputRef.current.dispatchEvent(new Event("keydown", { bubbles: true })); // Manually trigger dropdown
    }
  };

  const handleInputBlur = () => {
    setTimeout(() => {
      if (onBlurred && !placeSelected) {
        onBlurred();
      }
    }, 300); // Adding a delay before triggering onBlurred
  };

  // Handle clear button click
  const handleClear = () => {
    setInputValue("");
    onCleared();
    if (inputRef.current) {
      inputRef.current.value = ""; // Clear the input field
    }
  };

  // Reset function exposed to parent component
  const reset = () => {
    setInputValue("");
    setPlaceSelected(false);
    if (inputRef.current) {
      inputRef.current.value = "";
    }
    onCleared();
  };

  // Expose reset method to parent component
  useImperativeHandle(ref, () => ({
    reset,
  }));

  return (
    <div className="relative w-full">
      <input
        ref={inputRef}
        type="text"
        value={inputValue}
        placeholder={placeholder || "Enter Location"}
        className={`w-full pr-[33px] ${className}`}
        onChange={handleInputChange}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
      />
      {/* Clear button */}
      {inputValue && (
        <button
          type="button"
          className="absolute right-2 top-1/2 transform -translate-y-1/2 p-1 text-grayText text-[12px]"
          onClick={handleClear}
        >
          ✕
        </button>
      )}
    </div>
  );
});

export default GoogleAutoComplete;
