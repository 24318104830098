import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem("Authorization");
  if (token && config.headers) {
    config.headers["Authorization"] = `${token}`;
  }
  return config;
});

const setupAxiosInterceptors = (logout: () => void) => {
  api.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response?.status === 401) {
        console.log("401 Unauthorized, logging out");
        logout(); // Logout user if the response is 401
        window.location.href = "/sign-in"; // Redirect to login page
      }
      return Promise.reject(error);
    }
  );
};

export { api, setupAxiosInterceptors };
