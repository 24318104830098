import { api } from "../AxiosInstance";
import { Job, TripContainer } from "../interfaces/job.interface";
import { urlQueryBuilder } from "../utils/urlQueryBuilder";

const JobService = {
  getMyJobs: async (
    queryParams: {
      status?: string;
      skip?: number;
      limit?: number;
    } = {}
  ) => {
    const queryString = urlQueryBuilder(queryParams);
    const response = await api.get(
      `/job${queryString ? `?${queryString}` : ""}`
    );
    return response;
  },

  createJob: async (job: Job) => {
    const response = await api.post("/job", job);
    return response;
  },

  updateJob: () => {
    console.log("update job");
  },

  assignContainers: async (containersList: TripContainer[]) => {
    const response = await api.put(`/job/edit-trip`, containersList);
    return response;
  },

  getJobDetails: async (jobId: number) => {
    const response = await api.get("/job?jobId=" + jobId);
    return response;
  },

  trackDriver: async (driverId: number) => {
    const response = await api.get("/job?driverId=" + driverId);
    return response;
  },
};

export default JobService;
