import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  ReactNode,
} from "react";

// Define a context to share the Google Maps instance
const GoogleMapsContext = createContext<google.maps.Map | null>(null);

interface GoogleMapsLoaderProps {
  children: ReactNode;
}

export const GoogleMapsProvider: React.FC<GoogleMapsLoaderProps> = ({
  children,
}) => {
  const [googleMapsLoaded, setGoogleMapsLoaded] = useState<boolean>(false);
  const [mapInstance, setMapInstance] = useState<google.maps.Map | null>(null);

  useEffect(() => {
    const loadGoogleMapsScript = () => {
      if (!window.google?.maps) {
        const existingScript = document.getElementById("google-maps-script");
        if (!existingScript) {
          const script = document.createElement("script");
          script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places,marker`;
          script.id = "google-maps-script";
          script.async = true;
          script.defer = true;
          script.onload = () => setGoogleMapsLoaded(true);
          document.body.appendChild(script);
        } else {
          existingScript.onload = () => setGoogleMapsLoaded(true);
        }
      } else {
        setGoogleMapsLoaded(true);
      }
    };

    loadGoogleMapsScript();
  }, []);

  useEffect(() => {
    if (googleMapsLoaded && !mapInstance) {
      const mapOptions: google.maps.MapOptions = {};
      const map = new window.google.maps.Map(
        document.getElementById("map") as HTMLElement,
        mapOptions
      );
      setMapInstance(map);
    }
  }, [googleMapsLoaded, mapInstance]);

  return (
    <GoogleMapsContext.Provider value={mapInstance}>
      {children}
      <div id="map" style={{ width: "100%", height: "0px" }}></div>
    </GoogleMapsContext.Provider>
  );
};

export const useGoogleMaps = () => useContext(GoogleMapsContext);
