import "./JobDetails.scss";
import React, { useEffect, useState } from "react";
import {
  ContainerStats,
  JobBreakup,
  JobOrder,
} from "../../interfaces/job.interface";
import {
  calculateCycleTime,
  containerSizeStringToNumber,
  formatEpochDate,
  getContainerStatsFromJobOrder,
} from "../../utils/general";

import noData from "./../../assets/images/no-data-infographics.svg";
import MapWithPath from "../MapWithPath/MapWithPath";
import noJobInfoGraphics from "./../../assets/images/no-data-infographics.svg";

interface JobDetailsProps {
  job: JobOrder;
}

const containerStyle = {
  width: "100%",
  height: "100%",
};

const JobDetails: React.FC<JobDetailsProps> = ({ job }) => {
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [distance, setDistance] = useState<string>("");
  const [estimatedTime, setEstimatedTime] = useState<string>("");
  const [containerStats, setContainerStats] = useState<ContainerStats>();
  interface MapJob {
    sourceLatitude: string | number;
    sourceLongitude: string | number;
    destLatitude: string | number;
    destLongitude: string | number;
    source: string;
    destination: string;
  }

  interface MapMarker {
    position: google.maps.LatLngLiteral;
    title: string;
    label: string;
    imageUrl?: string;
  }

  useEffect(() => {
    if (job) {
      const stats = getContainerStatsFromJobOrder(job);
      setContainerStats(stats);
    }
  }, [job]);

  useEffect(() => {
    if (job) {
      const mapOptions: google.maps.MapOptions = {
        // mapId: "TERRAIN",
        streetViewControl: false,
        fullscreenControl: true,
        zoom: 8,
        center: {
          lat: parseFloat(job.sourceLatitude),
          lng: parseFloat(job.sourceLongitude),
        },
        styles: [
          {
            elementType: "labels",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "administrative",
            elementType: "geometry",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "administrative.land_parcel",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "administrative.neighborhood",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "poi",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "road",
            elementType: "labels.icon",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "transit",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
        ],
      };

      if (window.google) {
        const mapInstance = new window.google.maps.Map(
          document.getElementById("map") as HTMLElement,
          mapOptions
        );
        setMap(mapInstance);
        const sourcePosition = {
          lat: parseFloat(job.sourceLatitude),
          lng: parseFloat(job.sourceLongitude),
        };
        const destinationPosition = {
          lat: parseFloat(job.destLatitude),
          lng: parseFloat(job.destLongitude),
        };

        const createMarkerWithLabel = (
          position: google.maps.LatLngLiteral,
          title: string,
          label: string,
          imageUrl?: string,
          iconColor?: string
        ) => {
          const markerOptions: google.maps.MarkerOptions = {
            position,
            map: mapInstance,
            title,
            label,
            animation: google.maps.Animation.DROP,
          };

          markerOptions.label = {
            text: label,
            // color: "#59585b",
            color: "#000",
            fontWeight: "400",
          };

          if (iconColor) {
            markerOptions.icon = {
              path: "M10.5 0.368164C4.97662 0.368164 0.5 4.84586 0.5 10.3682C0.5 13.4471 1.89154 16.1999 4.07974 18.0348L10.5 24.4562L16.9203 18.0359C19.1085 16.2011 20.5 13.4482 20.5 10.3692C20.5 4.84586 16.0231 0.368164 10.5 0.368164ZM10.5 17.0312C6.99332 17.0312 4.15067 14.1885 4.15067 10.6818C4.15067 7.17624 6.99332 4.33359 10.5 4.33359C14.0067 4.33359 16.8493 7.17624 16.8493 10.6829C16.8493 14.1885 14.0067 17.0312 10.5 17.0312Z",
              fillColor: iconColor,
              fillOpacity: 1.0,
              strokeColor: "#000000",
              strokeWeight: 1,
              scale: 1,
              anchor: new google.maps.Point(12, 24),
            };
          }

          if (imageUrl) {
            markerOptions.icon = {
              url: imageUrl,
              scaledSize: new google.maps.Size(30, 30), // Adjust size as needed
            };
          }
          new window.google.maps.Marker(markerOptions);
        };

        createMarkerWithLabel(
          sourcePosition,
          job.source,
          job.source,
          "",
          "#cbd2d9"
        );

        createMarkerWithLabel(
          destinationPosition,
          job.destination,
          job.destination,
          "",
          "#19b5fe"
        );

        const directionsService = new google.maps.DirectionsService();
        const directionsRenderer = new google.maps.DirectionsRenderer({
          suppressMarkers: true,
          polylineOptions: {
            strokeColor: "#027cb5",
            strokeWeight: 3,
          },
        });
        directionsRenderer.setMap(mapInstance);

        directionsService.route(
          {
            origin: sourcePosition,
            destination: destinationPosition,
            travelMode: google.maps.TravelMode.DRIVING,
            provideRouteAlternatives: false,
            avoidHighways: false,
            avoidTolls: false,
          },
          (result, status) => {
            if (status === google.maps.DirectionsStatus.OK && result) {
              directionsRenderer.setDirections(result);

              // distance and estimated time from google api
              const leg = result.routes[0].legs[0];
              if (leg) {
                setDistance(leg.distance?.text || "N/A");
                setEstimatedTime(leg.duration?.text || "N/A");
              }
            } else {
              console.error(`Error fetching directions: ${status}`, result);
            }
          }
        );

        const trafficLayer = new google.maps.TrafficLayer();
        trafficLayer.setMap(mapInstance);
      }
    }
  }, [job]);

  return (
    <div className="job-details-page p-[20px] bg-white text-left overflow-y-auto">
      <div className="details_holder">
        <div className="row">
          <div className="label">Source</div>
          <div className="value truncate-one-line" title={job.source}>
            {job.source}
          </div>
        </div>
        <div className="row">
          <div className="label">Destination</div>
          <div className="value truncate-one-line" title={job.destination}>
            {job.destination}
          </div>
        </div>
        <div className="row">
          <div className="label">Job Type</div>
          <div className="value">{job.jobType}</div>
        </div>
        <div className="row">
          <div className="label">Movement</div>
          <div className="value">{job.cycle}</div>
        </div>
        <div className="row">
          <div className="label">Vessel Name</div>
          <div className="value">{job.vesselName}</div>
        </div>
        <div className="row">
          <div className="label">Service</div>
          <div className="value">{job.service}</div>
        </div>
        <div className="row">
          <div className="label">VOA</div>
          <div className="value">{job.voa}</div>
        </div>
        <div className="row">
          <div className="label">VOA / VIA</div>
          <div className="value">{job.voyageVia}</div>
        </div>
        <div className="row">
          <div className="label">Gate Open</div>
          <div className="value">{formatEpochDate(job.gateOpenDate)}</div>
        </div>
        <div className="row">
          <div className="label">Cut Off</div>
          <div className="value">{formatEpochDate(job.gateCloseDate)}</div>
        </div>
        <div className="row">
          <div className="label">Total</div>
          <div className="value">
            {job.jobBreakups.reduce(
              (total, breakup) => total + breakup.count,
              0
            )}{" "}
            Container (s)
          </div>
        </div>
        <div className="row">
          <div className="label">Cycle Time</div>
          <div className="value">
            {calculateCycleTime(job.gateOpenDate, job.gateCloseDate)}
          </div>
        </div>
      </div>

      <div className="inventory_details">
        <div className="label">Inventory</div>
        <div className="value">
          {job.jobBreakups.map((inventoryItem: JobBreakup, index) => (
            <div key={index} className="text-center">
              <span>
                {inventoryItem.count} x{" "}
                {containerSizeStringToNumber(inventoryItem.containerSize)}{" "}
                {inventoryItem.containerType}
              </span>
            </div>
          ))}
        </div>
      </div>

      <div className="trip_estimations">
        <div className="section">
          <div className="left">
            <div className="label">Distance</div>
            <div className="value">
              {job.tripEstimate.distanceInMts / 1000} Kms
            </div>
          </div>
        </div>
        <div className="section">
          <div className="right">
            <div className="label">Estimated Time</div>
            <div className="value">
              {job.tripEstimate.durationWithTrafficString}
            </div>
          </div>
        </div>
      </div>

      <div id="map" className="map_style" style={containerStyle}></div>
      {/* <MapWithPath
     startAddress={job.source}
     startLatLng={{
       lat: parseFloat(job.sourceLatitude),
       lng: parseFloat(job.sourceLongitude),
     }}
     endAddress={job.destination}
     endLatLng={{
       lat: parseFloat(job.destLatitude),
       lng: parseFloat(job.destLongitude),
     }}
   ></MapWithPath> */}
    </div>
  );
};

export default JobDetails;
